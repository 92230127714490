import React, { ReactElement } from "react";

import MainBanner from "@components/fastdebtconsolidation/mainBanner/mainBanner";
import { GetServerSideProps } from "next";

import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import WhatDebtRelief from "@components/fastdebtconsolidation/whatDebtRelief";
import OffersList from "@components/shared/offersList";
import FastDebtConsolidationWrapper from "@components/layouts/fastDebtConsolidationWrapper";
import HowDebtWorks from "@components/fastdebtconsolidation/howDebtWorks";
import DisadvantagesDebtSettlement from "@components/fastdebtconsolidation/disadvantagesDebtSettlement";

import DebtSettlement from "@components/fastdebtconsolidation/debtSettlement";
export default function FastDebtConsolidationHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <FastDebtConsolidationWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhatDebtRelief />
            <DebtSettlement />
            <HowDebtWorks />
            <DisadvantagesDebtSettlement />
        </FastDebtConsolidationWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
